.GameOver {
    text-align: center;
    color: var(--orange);
    font-size: 25px;
    margin: 10px;
}

.QuestionResult {
    margin-bottom: 15px;
}

.Share {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.FinalScore {
    color: var(--green);
}

.FinalTime {
    color: var(--red);
}

.Streak {
    color: var(--dark-purple);
}

.Footer {
    display: flex;
    padding-bottom: 20px;
    justify-content: space-around;
}

.MultipleChoiceMode {
    color: var(--red);
}

.ShareButton {
    animation: pulse 1.5s infinite; /* Adjust duration as needed */
    font-weight: bold;
    border: 2px solid black;
}
@keyframes pulse {
    0% {
        transform: scale(1); /* Start at normal size */
    }
    50% {
        transform: scale(1.05); /* Slightly increase size */
    }
    100% {
        transform: scale(1); /* Return to normal size */
    }
}
