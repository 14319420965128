/* Style for the overlay */
.Modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.4); /* Dim the background */
}

/* Modal Content Box */
.ModalContent {
    background-color: #fefefe;
    margin: 10% auto; /* Center it */
    padding: 20px;
    border: 1px solid #888;
    width: 70%;
    max-width: 500px;
    border-radius: 10px;
}
