.question {
    max-width: 100vw;
    word-wrap: break-word;
}

.QuestionHeader {
    font-size: 30px;
    font-weight: bold;
    padding-top: 10px;
    max-height: 39.5px;
    overflow: hidden;
}

.QuestionText {
    font-size: 24px;
    padding-top: 10px;
    display: flex;
    flex-direction: column-reverse;
}

.QuestionTextMultipleChoiceMode {
    overflow: scroll;
    max-height: calc(100vh - 300px);
}

@supports (-webkit-touch-callout: none) {
    .QuestionTextMultipleChoiceMode {
        /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
        max-height: calc(100vh - 400px);
    }
    .Chrome {
        max-height: calc(100vh - 400px);
    }
}

.Category {
    color: var(--dark-purple);
}

.CorrectOrIncorrectAnimation {
    position: fixed; /* Ensures the element stays in place even when scrolling */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    transition:
        opacity 0.3s ease,
        font-size 0.3s ease-in-out;
    opacity: 0;
    font-size: 5px;
    background-color: rgb(238, 238, 238, 0.3);
    padding: 10px;
    border-radius: 50px;
    font-weight: bold;
    font-family: futura;
    white-space: nowrap;
}

.CorrectOrIncorrectAnimation.visible {
    opacity: 1;
    font-size: 50px;
}

.CorrectAnimation {
    color: var(--green);
}

.IncorrectAnimation {
    color: var(--red);
}
