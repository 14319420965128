button {
    padding: 10px;
    color: black;
    border-radius: 10px;
    font-size: 15px;
    border: none;
    width: 120px;
    cursor: pointer;
}

button:active {
    background-color: rgb(183, 183, 183);
}
