:root {
    --dark-purple: #5e17eb;
    --light-purple: #8c52ff;
    --orange: #ff784d;
    --white: #fff;
    --green: #4caf50;
    --red: red;
}

.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 500px;
    margin: 0 auto;
    height: calc(100vh - 20px);
}

@supports (-webkit-touch-callout: none) {
    .App {
        /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
        height: -webkit-fill-available;
    }
}

.AppHeader {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    margin-top: 0;
}

.Title {
    font-size: 40px;
    font-family: futura;
    flex-grow: 1;
    padding-left: 25px; /* offset size of info icon */
    cursor: pointer;
}

.InfoIcon {
    height: 25px;
    justify-self: flex-end;
    cursor: pointer;
}

.QuickStyle {
    color: var(--dark-purple);
}

.QStyle {
    color: var(--light-purple);
}

.Content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
