.StatsFooter {
    display: flex;
    margin-top: 20px;
    justify-content: space-around;
    margin-bottom: 20px;
}

.StatBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
