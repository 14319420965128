.GameScreen {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.TopRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    align-items: center;
}

.BuzzBottomRow {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-grow: 1;
}

.MultipleChoiceBottomRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 1;
    position: fixed;
    bottom: 0;
    width: 100%;
    justify-content: center;
    gap: 10px;
    margin-bottom: 5px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.MultipleChoiceOption {
    border-radius: 10px;
    background-color: lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    color: black;
    width: 45%;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.MultipleChoiceOption:active {
    background-color: gray;
}

.BetweenQuestionsBottomRow {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-grow: 1;
}

.AnswerBottomRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.AnswerInputBottowRow {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
}

.BuzzButton {
    width: 230px;
    height: 90px;
    border-radius: 25px;
    background-color: red;
    font-size: large;
    color: black;
    cursor: pointer;
}

.BuzzButton:active {
    background-color: darkred;
}

.SkipButton {
    width: 90px;
    height: 90px;
    border-radius: 25px;
    background-color: lightgray;
    color: black;
    cursor: pointer;
}

.CenterContent {
}

.Correct {
    color: var(--green);
    font-weight: bold;
    margin-right: 20px;
}

.Incorrect {
    color: var(--red);
    font-weight: bold;
    margin-right: 20px;
}

.NextButton {
    align-self: flex-end;
    margin-left: 20px;
    cursor: pointer;
}

.BottomRow {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin-bottom: 20px;
}

.Source {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}

.DateAlreadyPlayed {
    background-color: var(--light-purple);
    border-radius: 50%;
}
