.StartScreen {
    /* position: relative; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* height: 50vh; */
    flex-grow: 1;
}

.Header {
    font-size: 20px;
    text-align: center;
}

.react-datepicker-wrapper {
    input {
        font-size: 20px;
        color: var(--orange);
        font-weight: bold;
        text-align: center;
    }
}

.StartButton {
    display: inline-block;
    padding: 12px 24px;
    border: none;
    border-radius: 10px;
    background-color: var(--green); /* Green */
    color: var(--white);
    font-size: 16px;
    cursor: pointer;
    min-width: 120px;
}

.StartButton:active {
    background-color: darkgreen;
}

.QQLogo {
    max-width: 80vw;
    align-self: center;
}

.Buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
}

.AlreadyPlayed {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ComeBack {
    text-align: center;
    margin-bottom: 10px;
}

.CountdownUntilNextDay {
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--dark-purple);
}

.GameMode {
    display: flex;
    flex-direction: column;
}

.GameModeTitle {
    display: flex;
    align-self: center;
    margin-bottom: 10px;
}

.ModeSelector {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.Bold {
    font-weight: bold;
}
